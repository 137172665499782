import Vue from 'vue'

Vue.filter('utcToLocal', function (value, format) {
    if (value) {
        let outputFormat = 'L LT'
        if (format) {
            if (format === 'date') outputFormat = 'L'
            if (format === 'time') outputFormat = 'LT'
            else outputFormat = format
        }
        return Vue.prototype.$date
            .utc(value, 'YYYY-MM-DD HH:mm:ss')
            .local()
            .format(outputFormat)
    }
})