export default [
  {
    path: '/auth/',
    component: () => import('@/views/auth/Index'),
    children: [
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/views/auth/Login'),
        meta: { requiresAuth: false }
      },
      {
        name: 'Logout',
        path: 'logout',
        component: () => import('@/views/auth/Logout'),
        meta: { requiresAuth: false }
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    children: [
      {
        name: 'Dashboard',
        path: '',
        component: () => import('@/views/dashboard/Dashboard'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Companies',
        path: 'companies',
        component: () => import('@/views/dashboard/companies/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Add Company',
        path: 'companies/add',
        component: () => import('@/views/dashboard/companies/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Edit Company',
        path: 'companies/edit/:id',
        component: () => import('@/views/dashboard/companies/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Locations',
        path: 'locations',
        component: () => import('@/views/dashboard/locations/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Add Location',
        path: 'locations/add',
        component: () => import('@/views/dashboard/locations/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Edit Location',
        path: 'locations/edit/:id',
        component: () => import('@/views/dashboard/locations/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Users',
        path: 'users',
        component: () => import('@/views/dashboard/users/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Add User',
        path: 'users/add',
        component: () => import('@/views/dashboard/users/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Edit User',
        path: 'users/edit/:id',
        component: () => import('@/views/dashboard/users/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Items',
        path: 'items',
        component: () => import('@/views/dashboard/items/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Add Item',
        path: 'items/add',
        component: () => import('@/views/dashboard/items/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Edit Item',
        path: 'items/edit/:id',
        component: () => import('@/views/dashboard/items/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Item Categories',
        path: 'item-categories',
        component: () => import('@/views/dashboard/item-categories/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Add Item Category',
        path: 'item-categories/add',
        component: () => import('@/views/dashboard/item-categories/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Edit Item Category',
        path: 'item-categories/edit/:id',
        component: () => import('@/views/dashboard/item-categories/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Plans',
        path: 'plans',
        component: () => import('@/views/dashboard/plans/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Add Plan',
        path: 'plans/add',
        component: () => import('@/views/dashboard/plans/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Edit Plan',
        path: 'plans/edit/:id',
        component: () => import('@/views/dashboard/plans/Form'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Plan Settings',
        path: 'plans/settings',
        component: () => import('@/views/dashboard/plans/Settings'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Subscriptions',
        path: 'subscriptions',
        component: () => import('@/views/dashboard/subscriptions/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Sales',
        path: 'sales',
        component: () => import('@/views/dashboard/sales/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'General Settings',
        path: 'settings/general',
        component: () => import('@/views/dashboard/settings/General'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Payment Gateway Settings',
        path: 'settings/payment-gateway',
        component: () => import('@/views/dashboard/settings/PaymentGateway'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Transactions',
        path: 'transactions',
        component: () => import('@/views/dashboard/transactions/Index'),
        meta: { requiresAuth: true }
      },
      {
        name: 'Profile',
        path: 'profile',
        component: () => import('@/views/dashboard/profile/Index'),
        meta: { requiresAuth: true }
      },


      
      {
        name: 'User Profile',
        path: 'pages/user',
        component: () => import('@/views/dashboard/pages/UserProfile'),
      },
      {
        name: 'Notifications',
        path: 'components/notifications',
        component: () => import('@/views/dashboard/component/Notifications'),
      },
      {
        name: 'Icons',
        path: 'components/icons',
        component: () => import('@/views/dashboard/component/Icons'),
      },
      {
        name: 'Typography',
        path: 'components/typography',
        component: () => import('@/views/dashboard/component/Typography'),
      },
      // Tables
      {
        name: 'Regular Tables',
        path: 'tables/regular-tables',
        component: () => import('@/views/dashboard/tables/RegularTables'),
      },
      // Maps
      {
        name: 'Google Maps',
        path: 'maps/google-maps',
        component: () => import('@/views/dashboard/maps/GoogleMaps'),
      },
      // Upgrade
      {
        name: 'Upgrade',
        path: 'upgrade',
        component: () => import('@/views/dashboard/Upgrade'),
      },
    ],
  },
  {
    name: '404 Not Found',
    path: "*",
    component: () => import('@/views/NotFound404'),
  }
]