const TOKEN_KEY = 'auth_token'

export default {
    getAuthToken() {
        return localStorage.getItem(TOKEN_KEY)
    },
    setAuthToken(token) {
        localStorage.setItem(TOKEN_KEY, token)
    },
    removeAuthToken() {
        localStorage.removeItem(TOKEN_KEY)
    }
}