import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes';
import localStorageService from '@/services/localStorageService'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !localStorageService.getAuthToken()) {
    next({
      path: '/auth/login',
      query: {
        redirect: to.fullPath
      }
    })
  } else {
    next()
  }
})

export default router