import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import i18n from '@/i18n'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'


Vue.use(VuetifyDialog, {
    context: {
        i18n,
        vuetify
    },
    warning: {},
    error: {
        actions: {
            false: {
                text() {
                    return this.$t('No')
                },
            },
            true: {
                text() {
                    return this.$t('Yes')
                },
                color: 'red'
            }
        },
    },
    prompt: {},
    toast: {
        position: 'top'
    },
})